import React, { Fragment, useCallback } from 'react';
import 'ress/ress.css';
import '../../styles/variables.css';
import '../../styles/base.css';

import { useAuth0 } from '../../contexts/Auth0Context';
import { useBackendApi } from '../../contexts/BackendApiContext';

import PublicRoot from '../../components/PublicRoot';
import SEO from '../../components/SEO';
import PageDefault from '../../components/PageDefault';
import ContentWrapper from '../../components/ContentWrapper';
import InlineBlockWrapper from '../../components/InlineBlockWrapper';

import SiteHeader from '../../organisms/SiteHeader';
import SiteFooter from '../../organisms/SiteFooter';
import GlobalNavigation from '../../organisms/GlobalNavigation';

import RegistrationSteps from '../../molecules/common/RegistrationSteps';

import ButtonDefault from '../../atoms/ButtonDefault';

import * as styles from './index.module.css';

const EmailVerificationTemplate = ({ pageContext }) => {
  const { pagePath } = pageContext;

  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const {
    createEmailVerificationJob,
    isCreateEmailVerificationJobLoading,
  } = useBackendApi();

  const onRequestVerificationEmailButtonClick = useCallback(() => {
    createEmailVerificationJob({
      variables: { client_id: process.env.GATSBY_AUTH0_CLIENT_ID },
    });
  }, [createEmailVerificationJob]);

  const onLoginButtonClick = useCallback(() => {
    const appState = { targetUrl: pagePath };
    loginWithRedirect(appState);
  }, [loginWithRedirect, pagePath]);

  return (
    <PublicRoot>
      <SEO path={pagePath} title="メールアドレスの確認" />
      <SiteHeader />

      <PageDefault>
        <ContentWrapper>
          <main className={styles['main']}>
            <section>
              <div className={styles['container']}>
                <div className={styles['contentArea']}>
                  <div className={styles['headingWrapper']}>
                    <h2 className={styles['heading']}>
                      <span className={styles['headingMain']}>
                        TORCH無料会員登録
                      </span>
                    </h2>
                  </div>

                  <div className={styles['registrationStepsSectionWrapper']}>
                    <section className={styles['registrationStepsSection']}>
                      <h2 className="sr-only">無料会員登録3ステップ</h2>

                      <div className={styles['registrationStepsWrapper']}>
                        <RegistrationSteps currentStep={2} />
                      </div>
                    </section>
                  </div>

                  <div className={styles['subHeadingWrapper']}>
                    <h1 className={styles['subHeading']}>
                      <span className={styles['subHeadingMain']}>
                        メールアドレスの確認
                      </span>
                    </h1>
                  </div>

                  {(() => {
                    if (isAuthenticated) {
                      return (
                        <Fragment>
                          <div className={styles['paragraphWrapper']}>
                            <p className={styles['paragraph']}>
                              <strong className={styles['paragraphStrong']}>
                                まだ会員登録は完了していません。
                              </strong>
                              <br />
                              登録のメールアドレス宛に確認メールをお送りしました。
                              <br />
                              送信されたメールをご確認いただき、認証手続きの完了をお願いいたします。
                            </p>
                          </div>

                          <InlineBlockWrapper
                            className={
                              styles['requestVerificationEmailButtonWrapper']
                            }
                          >
                            <ButtonDefault
                              disabled={isCreateEmailVerificationJobLoading}
                              onClick={onRequestVerificationEmailButtonClick}
                            >
                              確認メールの再送
                            </ButtonDefault>
                          </InlineBlockWrapper>

                          <InlineBlockWrapper
                            className={styles['backToHomeLinkWrapper']}
                          >
                            <a href="/" className={styles['anchor']}>
                              ホームに戻る
                            </a>
                          </InlineBlockWrapper>
                        </Fragment>
                      );
                    } else {
                      return (
                        <Fragment>
                          <div className={styles['paragraphWrapper']}>
                            <p className={styles['paragraph']}>
                              メールアドレスの確認はお済みですか?
                              <br />
                              登録のメールアドレス宛にメールアドレス確認のメールをお送りしました。
                              <br />
                              もし、認証リンクの有効期限がきれている場合は、ログインして確認メールの再送をお願いいたします。
                            </p>
                          </div>

                          <InlineBlockWrapper
                            className={styles['loginButtonWrapper']}
                          >
                            <ButtonDefault onClick={onLoginButtonClick}>
                              ログイン
                            </ButtonDefault>
                          </InlineBlockWrapper>
                        </Fragment>
                      );
                    }
                  })()}
                </div>
              </div>
            </section>
          </main>
        </ContentWrapper>
      </PageDefault>

      <GlobalNavigation />

      <SiteFooter />
    </PublicRoot>
  );
};

export default EmailVerificationTemplate;
