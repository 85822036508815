// extracted by mini-css-extract-plugin
export var anchor = "index-module--anchor--Y3LaZ";
export var backToHomeLinkWrapper = "index-module--back-to-home-link-wrapper--61c9c";
export var container = "index-module--container--NPYkn";
export var contentArea = "index-module--content-area--hRg25";
export var heading = "index-module--heading--9+H1Z";
export var headingMain = "index-module--heading-main--xf2Dc";
export var headingWrapper = "index-module--heading-wrapper--6nusx";
export var lede = "index-module--lede--eW3Sn";
export var ledeWrapper = "index-module--lede-wrapper---ncFA";
export var loginButtonWrapper = "index-module--login-button-wrapper--WdbWU";
export var main = "index-module--main--2qLit";
export var paragraph = "index-module--paragraph--BL79I";
export var paragraphStrong = "index-module--paragraph-strong--5oK4M";
export var paragraphWrapper = "index-module--paragraph-wrapper--0TxBp";
export var registrationStepsSection = "index-module--registration-steps-section--ckOLJ";
export var registrationStepsSectionWrapper = "index-module--registration-steps-section-wrapper--PJI1N";
export var registrationStepsWrapper = "index-module--registration-steps-wrapper--wGmjz";
export var requestVerificationEmailButtonWrapper = "index-module--request-verification-email-button-wrapper--WjhMl";
export var strong = "index-module--strong--H2QDS";
export var subHeading = "index-module--sub-heading--W7lm-";
export var subHeadingMain = "index-module--sub-heading-main--npsIv";
export var subHeadingWrapper = "index-module--sub-heading-wrapper--g4uv0";