import React from 'react';

import EmailVerificationTemplate from '../templates/EmailVerificationTemplate';

const EmailVerificationPage = () => (
  <EmailVerificationTemplate
    pageContext={{ pagePath: '/email-verification' }}
  />
);

export default EmailVerificationPage;
